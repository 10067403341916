import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "./css/index.scss"

import Arrow from "../images/arrow.svg"
import BackgroundImage from "gatsby-background-image"
import Auftritte from "../components/auftritte"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Octatonics" description="Hey, wir sind die Octatonics!" />

    <section className="section">
      <BackgroundImage
        Tag="section"
        className="background"
        fluid={data.desktop.childImageSharp.fluid}
        backgroundColor={`#B00000`}
      >
        <div id="first">
          <div id="logo">
            <Image alt="Octatonics Logo" filename="Logo-weiß.png" />
          </div>
          <div id="arrow">
            <Link to="/#about" title="weiter">
              <Arrow width="100%" height="100%" className="animated bounce" />
            </Link>
          </div>
        </div>
      </BackgroundImage>
    </section>

    <section className="section" id="about">
      <div className="image">
        <Image
          alt="Bild der Octatonics"
          filename="gruppenbild.jpg"
          style={{ borderRadius: "20px" }}
        />
      </div>
      <p className="text">
        Hey, wir sind die Octatonics. Wenn ihr Songwünsche habt dann...
        <br />
        moment das kommt erst noch! <br />
        <br />
        Nochmal von vorne: <br />
        Hey, wir sind die Octatonics und spielen einfach das, was uns Spaß
        macht. Ohne Leitung, ohne Zwang. <br />
        Bei Jazz, Rock, Pop und mehr sollte für jeden etwas dabei sein.
      </p>
    </section>

    <section className="section" id="events">
      <h2>Unsere nächsten Auftritte:</h2>
      <Auftritte size={3} newer={true} />
    </section>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
